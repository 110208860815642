/* TODO Block 1 und Block 2 verbinden, dass der Text vom einen in den anderen geschrieben wird
     NICHT mehr mit Content1 und Content2 arbeiten */

// NEWS TEIL

.app
  font-family: serif
  margin: 0
  text-align: justify

.color
  color: #E37823


.wrapper
  height: 100vh
  background-color: #F0F0F0

.headertable
  height: 10vh
  padding: 0


.header
  color: #FFFFFF
  background: #D0D0D0
  white-space: nowrap
  font-size: 5vh
  width: 100%
  height: 100%


.newstime
  padding-left: 5%
  width: 40%
  text-align: left
  font-size: 80%

.tau
  font-family: monospace
  width: 20%
  text-align: center
  font-size: 100%

.paper
  padding-right: 5%
  width: 40%
  text-align: right
  font-size: 80%


.title
  font-size: 4.5vh
  text-align: right
  padding: 3vh 2% 2vh 3%
  background-color: #FFFFFF
  font-family: sans-serif
  float: left
  width: 40%
  height: 20vh
  margin: 5vh 0 0 5%

.image
  position: relative
  display: inline-block
  padding: 3vh 3% 2vh 2%
  background-color: #FFFFFF
  float: right
  width: 40%
  height: 35vh
  margin: 5vh 5% 0 0


.img
  height: 100%
  width: auto
  max-width: 90%


small
  font-size: 1.5vh
  position: absolute
  bottom: 0
  transform: rotate(270deg)
  transform-origin: 0 0
  width: 100%

.block1
  font-size: 3vh
  padding: 3vh 2% 2vh 3%
  background-color: #FFFFFF
  float: left
  width: 40%
  height: 48vh
  margin: 0 0 5vh 5%
  overflow: hidden


.block2
  font-size: 3vh
  padding: 3vh 3% 2vh 2%
  background-color: #FFFFFF
  float: right
  width: 40%
  height: 33vh
  margin: 0 5% 5vh 0
  overflow: hidden
